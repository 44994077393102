import { Customer } from "@customers/customers-mgmt/models";
import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";

export class CardGroup implements Exportable {


  id:         number = 0;
  version:    number | undefined;
  name:       string | undefined;

  customerId: number | undefined;

  customer:   Customer = new Customer();


  constructor(){}

}

export class CardGroupFilter implements QueryFilter {

  name:      string | undefined;

}


export class CardGroupExporter implements Exporter  {

  name;
  customer;


  constructor() {


    this.name= {
      nameToShow: 'Nombre'
    };
    this.customer= {
      name: {
        nameToShow: 'Cliente'
      }
    };
  }
}




