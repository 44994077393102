// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

// Models
import { QueryParameters, QueryResults } from '@models/index';
import { CardGroup } from '@cards/card-groups/models/index';
import { Customer } from '@customers/customers-mgmt/models';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';
import { CustomerService } from '@customers/customers-mgmt/services/customer.service';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'cardGroup'


@Injectable({
  providedIn: 'root'
})
export class CardGroupService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils,
    private customerService: CustomerService) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }


  find(queryParameters: QueryParameters): Observable<QueryResults<CardGroup>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    let queryResults$ =  this.httpQueryUtils.executeQuery<CardGroup>( url, queryParameters)
      .pipe(
        switchMap((queryResults: QueryResults<CardGroup>) => {

          // return empty Observable in case of no items
          if( queryResults.items.length === 0 ) return of(queryResults);

          // gets arrays of Observables foreach element in "queryResults.items"
          const requestDetail_1: Observable<Customer>[] = this.getDetailObservablesCustomer(queryResults.items);

          // la caña de España!
          return forkJoin([...requestDetail_1])
            .pipe(
              map((arrayWithDetailData: any) => { // each item array is detail data from one Observable.

                const resultDetail_1 = arrayWithDetailData.slice(0, queryResults.items.length);

                queryResults.items
                  .map((item, i) => {
                    item.customer   = resultDetail_1[i];
                    return item;
                  });


                return queryResults;
              })
            );
        })
      )

    return  queryResults$;
  }


  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesCustomer(items: CardGroup[]): Observable<Customer>[] {

    return items.map((item: CardGroup) => {

      if (item.customerId && item.customerId>0) {
        return this.customerService.findById(item.customerId)
      } else {
        return of(new Customer());
      }

    });

  }


  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: CardGroup): Observable<CardGroup> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<CardGroup>(url, entity);
  }


  update(entity: CardGroup): Observable<CardGroup> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<CardGroup>(url, entity);
  }


  findById(id: number): Observable<CardGroup> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;
    return this.httpQueryUtils.executeQuery<CardGroup>(url, {})
            .pipe(
              map((queryResults: QueryResults<CardGroup>) => queryResults.items.slice(0,1) ),
              switchMap((items: CardGroup[]) => {

                // gets arrays of Observables foreach element in "queryResults.items"
                const requestDetail_1: Observable<Customer>[] = this.getDetailObservablesCustomer(items);

                // la caña de España!
                return forkJoin([...requestDetail_1])
                  .pipe(
                    map((arrayWithDetailData: any) => { // each item array is detail data from one Observable.

                      const resultDetail_1 = arrayWithDetailData.slice(0, items.length);

                      items
                        .map((item: CardGroup, i) => {
                          item.customer  = resultDetail_1[i];
                          return item;
                        });


                      return items[0];
                    })
                  );


              })
            )
  }

}
